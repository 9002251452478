var equalHeight = function(selector){
	var items = jQuery(selector);
	var height = 0;

	var getHeight = function(index){
		var item = jQuery(this);
		if(item.height() > height){
			height = item.height();
		}
	}
	var setHeight = function(index){
		jQuery(this).height(height);
	}

	items.css('height', 'auto');
	jQuery.each(items, getHeight);
	jQuery.each(items, setHeight);
};

(function($){
	// FitVids
	$.fn.fitVids = function( options ) {
		var settings = {
			customSelector: null
		};

		if(!document.getElementById('fit-vids-style')) {

			var div = document.createElement('div'),
			ref = document.getElementsByTagName('base')[0] || document.getElementsByTagName('script')[0],
			cssStyles = '&shy;<style>.fluid-width-video-wrapper{width:100%;position:relative;padding:0;}.fluid-width-video-wrapper iframe,.fluid-width-video-wrapper object,.fluid-width-video-wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}</style>';

			div.className = 'fit-vids-style';
			div.id = 'fit-vids-style';
			div.style.display = 'none';
			div.innerHTML = cssStyles;

			ref.parentNode.insertBefore(div,ref);

		}

		if ( options ) {
			$.extend( settings, options );
		}

		return this.each(function(){
			var selectors = [
			"iframe[src*='player.vimeo.com']",
			"iframe[src*='youtube.com']",
			"iframe[src*='youtube-nocookie.com']",
			"iframe[src*='kickstarter.com'][src*='video.html']",
			"object",
			"embed"
			];

			if (settings.customSelector) {
				selectors.push(settings.customSelector);
			}

			var $allVideos = $(this).find(selectors.join(','));
			$allVideos = $allVideos.not("object object"); // SwfObj conflict patch

			$allVideos.each(function(){
				var $this = $(this);
				if (this.tagName.toLowerCase() === 'embed' && $this.parent('object').length || $this.parent('.fluid-width-video-wrapper').length) { return; }
				var height = ( this.tagName.toLowerCase() === 'object' || ($this.attr('height') && !isNaN(parseInt($this.attr('height'), 10))) ) ? parseInt($this.attr('height'), 10) : $this.height(),
				width = !isNaN(parseInt($this.attr('width'), 10)) ? parseInt($this.attr('width'), 10) : $this.width(),
				aspectRatio = height / width;
				if(!$this.attr('id')){
					var videoID = 'fitvid' + Math.floor(Math.random()*999999);
					$this.attr('id', videoID);
				}
				$this.wrap('<div class="fluid-width-video-wrapper"></div>').parent('.fluid-width-video-wrapper').css('padding-top', (aspectRatio * 100)+"%");
				$this.removeAttr('height').removeAttr('width');
			});
		});
	};
})(jQuery);

jQuery(document).ready(function($) {

	// Emergency Alerts
	// $.get('/webapps/alerts/getAlerts.aspx', function(alerts){
	// 	$('body').prepend(alerts);
	// });

	// Navigation Toggles
	$('.top-navigation .toggle').click(function(){
		$(this).toggleClass('active');
		$('#top-navigation').toggleClass('open');
	});
	$('.main-navigation .toggle').click(function(){
		$(this).toggleClass('active');
		$('#main-navigation').toggleClass('open');
	});

	// FitVids Call
	$('.fitvids').fitVids();

	// Accordion Script
	$('.accordion').not('.bio').click(function(e){
		e.preventDefault();
		$(this).find('.content').slideToggle();
		$(this).find('.readmore').slideToggle();
	});
	// Accordion Bio Script
	$('.accordion.bio .readmore').click(function(e){
		e.preventDefault();
		if($(this).hasClass('clicked')){
			$(this).parents('.accordion').find('.readmore').toggleClass('clicked').text("Click to read more");
			$(this).parents('.accordion').find('.content').slideToggle();
		}
		else{
			$(this).parents('.accordion').find('.readmore').toggleClass('clicked').text("Click to collapse");
			$(this).parents('.accordion').find('.content').slideToggle();
		}
	});

});